import React, { useContext } from "react"

import { NonIdealState, Button } from "@blueprintjs/core"

import PublicLayout from "Layouts/PublicLayout"

import Card from "Systems/Core/Components/Card"

import SessionContext from "Modules/Session/Contexts/SessionContext"

function SessionRecovery() {
  let session = useContext(SessionContext)

  return (
    <PublicLayout>
      <Card>
        <Card.Content>
          <NonIdealState
            icon="issue"
            title="Oops!"
            description="Unable to load user details."
            action={
              <Button
                intent="primary"
                text="Try Again"
                onClick={session.recover}
              />
            }
          />
        </Card.Content>
      </Card>
    </PublicLayout>
  )
}

export default SessionRecovery
