import { createMachine, assign } from "Systems/Core"

import sendValidateTokenRequest from "Modules/Session/Requests/sendValidateTokenRequest"

import sendLoginRequest from "Modules/Login/Requests/sendLoginRequest"

import sendGetUserRequest from "Modules/User/Requests/sendGetUserRequest"

let sessionMachine = createMachine(
  {
    id: "session",
    context: {
      token: null,
      user: null
    },
    initial: "initializing",
    states: {
      initializing: {
        on: {
          "": [
            {
              target: "restoring",
              cond: "hasToken"
            },
            {
              target: "inactive"
            }
          ]
        }
      },
      restoring: {
        initial: "loading",
        states: {
          loading: {
            invoke: {
              src: "validateToken",
              onDone: "success",
              onError: [
                {
                  target: "failure",
                  actions: "notifyExpired",
                  cond: "didExpire"
                },
                {
                  target: "failure"
                }
              ]
            }
          },
          success: {
            type: "final"
          },
          failure: {
            type: "final",
            entry: ["resetToken", "notifyToken"]
          }
        },
        onDone: [
          {
            target: "active",
            cond: "hasToken"
          },
          {
            target: "inactive"
          }
        ]
      },
      inactive: {
        initial: "idle",
        states: {
          idle: {
            on: {
              LOGIN: "loading"
            }
          },
          loading: {
            invoke: {
              src: "login",
              onDone: "success",
              onError: "failure"
            }
          },
          success: {
            type: "final",
            entry: ["setToken", "notifyToken"]
          },
          failure: {
            entry: "notifyLoginFailure",
            on: {
              LOGIN: "loading"
            }
          }
        },
        onDone: "active"
      },
      active: {
        exit: ["resetToken", "notifyToken"],
        initial: "loading",
        states: {
          loading: {
            invoke: {
              src: "getUser",
              onDone: "success",
              onError: "failure"
            }
          },
          success: {
            entry: "setUser",
            exit: "resetUser"
          },
          failure: {
            entry: "notifyGetUserFailure",
            on: {
              RETRY: "loading"
            }
          }
        },
        on: {
          LOGOUT: "inactive"
        }
      }
    }
  },
  {
    guards: {
      hasToken: ctx => ctx.token,
      didExpire: (_, e) => e.data.status === 401
    },
    actions: {
      setUser: assign({ user: (_, e) => e.data }),
      resetUser: assign({ user: null }),
      setToken: assign({ token: (_, e) => e.data }),
      resetToken: assign({ token: null })
    },
    services: {
      validateToken: () => sendValidateTokenRequest(),
      getUser: () => sendGetUserRequest(),
      login: (_, e) => sendLoginRequest(e.payload)
    }
  }
)

export default sessionMachine
