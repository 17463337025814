/** @jsx jsx */

import { jsx } from "theme-ui"

function Spacer(props) {
  return (
    <span
      sx={{ display: "block", mt: props.size, ml: props.size }}
      aria-hidden
    />
  )
}

export default Spacer
