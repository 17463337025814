/** @jsx jsx */

import { jsx } from "theme-ui"

function Col(props) {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: props.justifyContent,
        alignItems: props.alignItems
      }}
    >
      {props.children}
    </div>
  )
}

export default Col
