import sendRequest from "Systems/Core/Utils/sendRequest"

function sendValidateTokenRequest() {
  return sendRequest({
    method: "post",
    path: "/auth0/validate_jwt"
  })
}

export default sendValidateTokenRequest
