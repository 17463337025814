/** @jsx jsx */

import { jsx } from "theme-ui"

function Row(props) {
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: props.justifyContent,
        alignItems: props.alignItems
      }}
    >
      {props.children}
    </div>
  )
}

export default Row
