import React, { Component } from "react"

import { Classes, NonIdealState, Button } from "@blueprintjs/core"

import PublicLayout from "Layouts/PublicLayout"

import Spacer from "Systems/Core/Components/Spacer"
import Col from "Systems/Core/Components/Col"
import Card from "Systems/Core/Components/Card"

class ErrorBoundary extends Component {
  state = { error: null }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo })
    // TODO: crash report
  }

  render() {
    if (this.state.error) {
      return (
        <PublicLayout>
          <Card>
            <Card.Content>
              <NonIdealState
                icon="issue"
                title="The app cashed."
                description={
                  <div className={Classes.RUNNING_TEXT}>
                    <p>Please share the following error with the team:</p>
                    <pre
                      style={{
                        textAlign: "left",
                        maxHeight: 120,
                        overflow: "auto"
                      }}
                    >
                      {this.state.error.stack}
                    </pre>
                  </div>
                }
                action={
                  <Col alignItems="center">
                    <>
                      <Button
                        intent="primary"
                        text="Reload Page"
                        onClick={() => window.location.reload(true)}
                      />
                      <Spacer size={6} />
                      <p>
                        If reloading the page didn't work, try to clear the
                        storage:
                      </p>
                      <Spacer size={4} />
                      <Button
                        intent="danger"
                        text="Clear Storage and Reload"
                        onClick={() => {
                          if (
                            window.confirm(
                              "You'll lose all widget configuration.\n\nDo you want to proceed?"
                            )
                          ) {
                            localStorage.clear()
                            window.location.reload(true)
                          }
                        }}
                      />
                    </>
                  </Col>
                }
              />
            </Card.Content>
          </Card>
        </PublicLayout>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
