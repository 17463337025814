import React, { Suspense, lazy } from "react"

import { ThemeProvider, Styled } from "theme-ui"
import { Router } from "@reach/router"

import ErrorBoundary from "Systems/Core/Components/ErrorBoundary"
import FullPageSpinner from "Systems/Core/Components/FullPageSpinner"

import SessionContext from "Modules/Session/Contexts/SessionContext"
import SessionRecovery from "Modules/Session/Components/SessionRecovery"
import useSession from "Modules/Session/Hooks/useSession"

let Login = lazy(() => import("Modules/Login"))
let Dashboard = lazy(() => import("Modules/Dashboard"))

let theme = {
  colors: {
    blueGray: [
      "#f0f4f8",
      "#d9e2ec",
      "#bcccdc",
      "#9fb3c8",
      "#829ab1",
      "#627d98",
      "#486581",
      "#334e68",
      "#243b53",
      "#102a43"
    ]
  },
  fonts: {
    heading: "system-ui, sans-serif",
    body: "system-ui, sans-serif"
  },
  space: [
    0,
    4,
    8,
    12,
    16,
    24,
    32,
    48,
    64,
    96,
    128,
    192,
    256,
    384,
    512,
    640,
    768
  ],
  sizes: {
    container: [0, 500, 1200]
  },
  styles: {
    root: {
      backgroundColor: "#f4f6f9",
      fontFamily: "body"
    }
  }
}

function App() {
  let session = useSession()

  return (
    <ThemeProvider theme={theme}>
      <Styled.root>
        <ErrorBoundary>
          <Suspense fallback={<FullPageSpinner />}>
            <SessionContext.Provider value={session}>
              <Router>
                {session.error ? (
                  <SessionRecovery default />
                ) : session.user ? (
                  <Dashboard default />
                ) : (
                  <Login default />
                )}
              </Router>
            </SessionContext.Provider>
          </Suspense>
        </ErrorBoundary>
      </Styled.root>
    </ThemeProvider>
  )
}

export default App
