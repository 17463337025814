import sendRequest from "Systems/Core/Utils/sendRequest"

import createOrganization from "Modules/Organization/Factories/createOrganization"

function sendGetUserRequest() {
  return sendRequest({
    method: "post",
    path: "/authenticate",
    transformData: data => ({
      id: data.user.id,
      name: `${data.user.first_name} ${data.user.last_name}`,
      email: data.user.email,
      organizations: data.organizations.map(createOrganization)
    })
  })
}

export default sendGetUserRequest
