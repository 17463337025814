/** @jsx jsx */

// eslint-disable-next-line
import React, { createContext, useContext } from "react"

import { jsx } from "theme-ui"

import { Classes, H5, Card as BaseCard } from "@blueprintjs/core"

import Spacer from "Systems/Core/Components/Spacer"
import Row from "Systems/Core/Components/Row"

let Context = createContext()

function Card(props) {
  return (
    <Context.Provider
      value={{
        fullHeight: props.fullHeight,
        loading: props.loading
      }}
    >
      <BaseCard
        elevation={1}
        sx={{
          width: "100%",
          height: props.fullHeight ? "100%" : null,
          display: "flex",
          flexDirection: "column",
          "& [data-hidden='true']": {
            opacity: 0,
            transition: "opacity 0.3s"
          },
          "&:hover [data-hidden='true']": {
            opacity: 1
          }
        }}
      >
        {props.children}
      </BaseCard>
    </Context.Provider>
  )
}

Card.Header = function CardHeader(props) {
  return (
    <>
      <Row justifyContent="space-between" alignItems="center">
        <H5>{props.title}</H5>
        {props.rightAddon}
      </Row>
      <Spacer size={4} />
    </>
  )
}

Card.Content = function CardContent(props) {
  let context = useContext(Context)

  return (
    <div
      className={context.loading ? Classes.SKELETON : undefined}
      sx={{
        height: context.fullHeight ? "100%" : null,
        display: "flex",
        flexDirection: "column",
        justifyContent: props.justifyContent,
        alignItems: props.alignItems,
        overflow: props.overflow
      }}
    >
      {props.children}
    </div>
  )
}

export default Card
