let localhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl)
    .then(response => {
      let contentType = response.headers.get("content-type")

      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })

        return
      }

      registerValidSW(swUrl, config)
    })
    .catch(() =>
      console.info(
        "No internet connection found. App is running in offline mode."
      )
    )
}

export function register(config) {
  if (
    !("serviceWorker" in navigator) ||
    process.env.NODE_ENV !== "production"
  ) {
    return
  }

  let publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)

  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    return
  }

  window.addEventListener("load", () => {
    let swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

    if (localhost) {
      checkValidServiceWorker(swUrl, config)

      navigator.serviceWorker.ready.then(() =>
        console.info(
          "This web app is being served cache-first by a service worker."
        )
      )

      return
    }

    registerValidSW(swUrl, config)
  })
}

function registerValidSW(swUrl, config = {}) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        let installing = registration.installing

        if (installing == null) {
          return
        }

        installing.onstatechange = () => {
          if (installing.state !== "installed") {
            return
          }

          if (navigator.serviceWorker.controller) {
            console.info(
              "New content is available and will be used when all tabs for this page are closed."
            )

            if (config.onUpdate) {
              config.onUpdate(registration)
            }
          } else {
            console.info("Content is cached for offline use.")

            if (config.onSuccess) {
              config.onSuccess(registration)
            }
          }
        }
      }
    })
    .catch(error =>
      console.error("Error during service worker registration:", error)
    )
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(registration =>
      registration.unregister()
    )
  }
}
