import React from "react"

import * as BC from "@blueprintjs/core"
import * as BS from "@blueprintjs/select"

import {
  useMachine as baseUseMachine,
  useService as baseUseService
} from "@xstate/react"

// ------------------------------
// Utils

export {
  Machine as createMachine,
  assign,
  spawn,
  send,
  sendParent
} from "xstate"

// ------------------------------
// Components

export function Select(props) {
  let activeItem = props.items.find(
    item => item.value === props.activeItemValue
  )

  return (
    <BS.Select
      items={props.items}
      activeItem={activeItem}
      itemRenderer={(item, props) => (
        <BC.Menu.Item
          key={item.label}
          text={item.label}
          icon={item.icon}
          active={props.modifiers.active}
          onClick={props.handleClick}
        />
      )}
      filterable={props.filterable === undefined ? false : props.filterable}
      onItemSelect={item => {
        if (activeItem && activeItem.value === item.value) {
          return
        }

        props.onItemSelect(item)
      }}
    >
      <BC.Button
        text={activeItem ? activeItem.label : "None"}
        icon={activeItem ? activeItem.icon : undefined}
        rightIcon="double-caret-vertical"
      />
    </BS.Select>
  )
}

// ------------------------------
// Hooks

export function useMachine(machine, config = {}) {
  let [state, baseSend] = baseUseMachine(machine, config)

  let meta = Object.values(state.meta).reduce(
    (acc, v) => ({ ...acc, ...v }),
    {}
  )

  function send(type, payload) {
    return baseSend({ type, payload })
  }

  return { state, meta, send }
}

export function useService(service) {
  let [state, baseSend] = baseUseService(service)

  let meta = Object.values(state.meta).reduce(
    (acc, v) => ({ ...acc, ...v }),
    {}
  )

  function send(type, payload) {
    return baseSend({ type, payload })
  }

  return { state, meta, send }
}
