/** @jsx jsx */

import { jsx } from "theme-ui"

function InnerSpacing(props) {
  return (
    <div
      sx={
        Array.isArray(props.size)
          ? { px: props.size[1], py: props.size[0] }
          : { p: props.size }
      }
    >
      {props.children}
    </div>
  )
}

export default InnerSpacing
