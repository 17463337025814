import { useMachine } from "Systems/Core"

import sendNotification from "Systems/Core/Utils/sendNotification"

import sessionMachine from "Modules/Session/Machines/sessionMachine"

function useSession() {
  let sessionService = useMachine(sessionMachine, {
    context: {
      token: localStorage.getItem("token")
    },
    actions: {
      notifyToken: ctx => {
        if (ctx.token) {
          localStorage.setItem("token", ctx.token)
        } else {
          localStorage.removeItem("token")
        }
      },
      notifyExpired: () =>
        sendNotification({
          intent: "warning",
          message: "Your session expired. Please login again."
        }),
      notifyLoginFailure: (_, e) =>
        sendNotification({ intent: "danger", message: e.data.message }),
      notifyGetUserFailure: (_, e) =>
        sendNotification({
          intent: "danger",
          message: e.data.message,
          action: {
            label: "Retry",
            onClick: () => sessionService.send("RETRY")
          }
        })
    }
  })

  let user = sessionService.state.context.user
  let loading =
    sessionService.state.matches("restoring.loading") ||
    sessionService.state.matches("inactive.loading") ||
    sessionService.state.matches("active.loading")
  let error = sessionService.state.matches("active.failure")

  function login(params) {
    sessionService.send("LOGIN", params)
  }

  function logout() {
    sessionService.send("LOGOUT")
  }

  function recover() {
    sessionService.send("RETRY")
  }

  return { user, loading, error, login, logout, recover }
}

export default useSession
