import React from "react"
import { render } from "react-dom"

import * as Sentry from "@sentry/browser"

import { FocusStyleManager } from "@blueprintjs/core"

import App from "./App"

import * as serviceWorker from "./serviceWorker"

import "./index.css"

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV !== "development"
})

FocusStyleManager.onlyShowFocusOnTabs()

render(<App />, document.getElementById("root"))

serviceWorker.unregister()
