import axios from "axios"

function sendRequest(params) {
  return axios
    .request({
      method: params.method,
      url: params.url || `${process.env.REACT_APP_API_URL}${params.path}/`,
      params: params.query,
      headers: localStorage.getItem("token")
        ? {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            ...params.headers
          }
        : params.headers,
      data: params.body
    })
    .then(response =>
      params.transformData ? params.transformData(response.data) : response.data
    )
    .catch(error => {
      if (error.response === undefined) {
        throw error
      }

      let message = params.extractErrorMessage
        ? params.extractErrorMessage(error.response.data)
        : error.response.data.message

      if (typeof message !== "string" || message.length === 0) {
        // TODO: report error
        message =
          "Please try again. An internal error has occurred and we've noted the issue. If this error continues to occur please contact support."
      }

      let _error = new Error(message)
      _error.status = error.response.status

      throw _error
    })
}

export default sendRequest
