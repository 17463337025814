import sendRequest from "Systems/Core/Utils/sendRequest"

function sendLoginRequest(params) {
  return sendRequest({
    method: "post",
    url: `${process.env.REACT_APP_AUTH0_URL}/oauth/ro`,
    body: {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      connection: "Username-Password-Authentication",
      scope: "openid picture name email",
      username: params.email,
      password: params.password
    },
    transformData: data => data.id_token,
    extractErrorMessage: data => data.error_description
  })
}

export default sendLoginRequest
