/** @jsx jsx */

import { jsx } from "theme-ui"

import { Spinner } from "@blueprintjs/core"

function FullPageSpinner(props) {
  return (
    <div
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Spinner />
    </div>
  )
}

export default FullPageSpinner
