import { Position, Toaster } from "@blueprintjs/core"

let toaster = Toaster.create({ position: Position.TOP })

function getIconByIntent(intent) {
  return {
    success: "tick-circle",
    warning: "warning-sign",
    danger: "error"
  }[intent]
}

function sendNotification(params) {
  toaster.show({
    intent: params.intent,
    icon: params.icon || getIconByIntent(params.intent),
    message: params.message,
    action: params.action
      ? {
          text: params.action.label,
          icon: params.action.icon,
          onClick: params.action.onClick
        }
      : undefined,
    timeout: params.timeout,
    onDismiss: params.onDismiss
  })
}

export default sendNotification
