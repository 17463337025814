/** @jsx jsx */

import { jsx } from "theme-ui"

import Spacer from "Systems/Core/Components/Spacer"
import InnerSpacing from "Systems/Core/Components/InnerSpacing"
import Container from "Systems/Core/Components/Container"

import logo from "Layouts/PublicLayout/Assets/logo.png"

function PublicLayout(props) {
  return (
    <div
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Container size={1}>
        <main>
          <InnerSpacing size={5}>
            <InnerSpacing size={[0, 7]}>
              <img
                src={logo}
                alt="DNSFilter Analytics"
                sx={{
                  maxWidth: "100%",
                  m: "0 auto",
                  display: "block"
                }}
              />
            </InnerSpacing>
            <Spacer size={5} />
            {props.children}
          </InnerSpacing>
        </main>
      </Container>
    </div>
  )
}

export default PublicLayout
