/** @jsx jsx */

import { jsx } from "theme-ui"

function Container(props) {
  return (
    <div
      sx={{
        width: "100%",
        maxWidth: `container.${props.size}`,
        m: "0 auto"
      }}
    >
      {props.children}
    </div>
  )
}

export default Container
